<template>
<div class="wealth">
<!--  导航-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--第一层-->
  <div class="w-first">
    <img src="../../assets/img94.png">
    <div class="liang">
      <span class="liang1">财富管理系统</span>
      <span class="liang2">所属类型：定制化开发案例</span>
      <span class="liang3">财富管理系统从资产配置、财务规划、能力展示、业务协同全面助理财富顾问进行数字化展业，帮助企业持续开展客群经营，通过系统落实财富管理服务流程。</span>
    </div>
  </div>
<!--  第二层-->
  <div class="w-second">
    <div class="zss">
      <span class="subhead">产品概述</span>
      <span class="infoBox1"
      >《2019-2021年发展战略规划纲要》指出“全面发挥零售客户服务和企业机构客户服务两大战略体系的集群效能，实现财富管理国际化三大战略引擎强力拉动。<br />经纪业务落实以客户为中心的经营思路，大力拓展机构业务、财富管理业务和个人业务，强化分支机构管理，完善客户开发服务体系，做大客户规模，构建投顾投研服务品牌，逐步搭建最大、最全、最专业的金融产品超市，提升交易与配置服务能力，着力向财富管理转型。<br />财富管理系统是以客户为中心，以客户视角和买方思维为指引，全面对接集团资源，实现财富管理服务模式的系统化落地执行，为投资顾问向客户提供一站式的综合服务解决方案提供系统支持。<br
      /></span>
      <span class="subhead">功能描述</span>
      <img class="im2" src="../../assets/img93.png">
      <span class="subhead">产品模块</span>
      <span class="paragraph1"
      >1.资产配置与策略研究-市场预测与分析、资产配置模型管理、量化策略研究<br />2.产品筛选与组合构建-品种支持（公募、私募、资管、收益凭证……）、产品筛选、组合构建和分析、产品评价、人员尽调<br />3.咨询服务支持-新闻资讯、研究报告、金融产品专题研究、金融产品尽调、资产配置专题研究<br />4.解决方案和报告书管理-方案模板库、方案管理、定期报告管理、业绩报告展示、再平衡<br />5.客群经营/客户管理-客群宫格和分群管理、顾问式营销过程管理、客户联系管理（营销文件夹和话术库）、客户需求管理<br />6.拓客和资产配置工具-拓客工具、资产配置工具、签单工具<br />7.专家系统和业务协同-专家预约、视频会议、电话会议、业务需求与协同、业务协同运营管理<br />8.一站式运营支持-服务收费支持、风控和合规、运营事务支持</span
      >
    </div>
  </div>
<!--  第三层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "WeaLth",
  data() {
  return {
    cur: 0,
    hzData:[
      {
        hzz:"金融行业",
        url:'/FinAnce'
      },
      {
        hzz:"制造行业",
        url:'/Make'
      },
      {
        hzz:"零售行业",
        url:'/ReTail'
      },
      {
        hzz:"汽车行业",
        url:'/AutoMobile'
      },
    ],
    hzDatas:[
      {
        hzz:"供应商管理系统",
        url:'/IndepenDent'
      },
      {
        hzz:"企业数字化平台",
        url:'/IndepenDent'
      },
    ],
    hzDataes:[
      {
        hzz:"定制化开发服务",
        url:'/CustoMization'
      },
      {
        hzz:"产品化解决方案服务",
        url:'/Product'
      },
      {
        hzz:"平台研发",
        url:'/PlatForm'
      },
      {
        hzz:"咨询服务",
        url:'/DigiTization'
      },
      {
        hzz:"运维服务",
        url:'/OperAtion'
      },
      {
        hzz:"外包服务",
        url:'/Epiboly'
      },
    ],
    newData: [
      {
        cname: "客户案例",
        url:'/Clien'
      },
      {
        cname: "合作伙伴",
        url:'/Partner'
      },
      {
        cname: "公司信息",
        url:'/information'
      }
    ],
    foolDatas: [
      {
        lei: "金融行业",
        url: '/FinAnce'
      },
      {
        lei: "制造行业",
        url: 'Make'
      },
      {
        lei: "零售行业",
        url: '/ReTail'
      },
      {
        lei: "汽车行业",
        url:'/AutoMobile'
      },
    ],
    foolDatass: [
      {
        lei1: "供应商管理系统",
        url: '/IndepenDent'
      },
      {
        lei1: "企业经营数字化平台",
        url:'/IndepenDent'
      },
    ],
    foolDatasss: [
      {
        lei2: "定制化开发服务",
        url:'/CustoMization'
      },
      {
        lei2: "产品化解决方案服务",
        url:'/Product'
      },
      {
        lei2: "平台研发",
        url:'/PlatForm'
      },
      {
        lei2: "咨询服务",
        url:'/DigiTization'
      },
      {
        lei2: "运营服务",
        url:'/OperAtion'
      },
      {
        lei2: "外包服务",
        url:'/Epiboly'
      },
    ],
    foolDataes: [

    ],
    foolDataess: [

    ],
  }
},
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')
    },
    xx(){
      window.open('/information','_blank')
    },
    cl(){
      window.open('/Clien','_blank')
    }
  }

}
</script>

<style scoped>
.zss{
  display: flex;
  flex-direction: column;
}
.wealth{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  position: relative;
  width: 100%;
}
/*第一层*/
.w-first{
  width: 100%;
  /*min-width: 1920px;*/
  height: 331px;
  overflow: hidden;
  position: relative;
  background: center top no-repeat;
  -webkit-transition: height .6s,background,.6s;
  transition: height .6s,background,.6s;
}
.w-first img{
  position: absolute;
  left: 50%;
  top: 0;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
}
.w-first .liang{
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 632px;
  height: 179px;
  margin-top: 74px;
  margin-left: 45%;
}
.w-first .liang .liang1{
  width: 144px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
.w-first .liang .liang2{
  width: 192px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.71);
  line-height: 22px;
}
.w-first .liang .liang3{
  width: 632px;
  height: 72px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
}
/*第二层*/
.w-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1195px;
}
.w-second .subhead{
  margin-top: 64px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-bottom: 24px;
}
.w-second .infoBox1{
  width: 1180px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.w-second .im2{
  display: block;
  width: 831px;
  height: 427px;
}
.w-second .paragraph1 {
  width: 1180px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
/*第三层*/
.w-thirdy{
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/img95.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 286px;
}
.w-thirdy .lx{
  width: 500px;
  font-size: 25px;
  color: black;
  margin: 46px auto;
}
.w-thirdy .lx2{
  width: 226px;
  height: 48px;
  background: #124BFF;
  margin: 25px auto;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
}
.ground-floor {
  width: 100%;
  height: 527px;
  background-color: #262C50;
}
.ground-floor .fbox {
  display: inline-block;
  width: 100%;
  height: 465px;
  margin-top: 48px;
}
.ground-floor .fbox1 {
  display: flex;
  flex-direction: column;
  width: 1126px;
  height: 465px;
  margin: 0 auto;
  /*background-color: #FFFFFF;*/
}
.ground-floor .fbox1 .fbox2 {
  display: flex;
  justify-content: space-between;
  width: 1126px;
  height: 394px;
}
.ground-floor .fbox1 .cut-line1{
  width: 1180px;
  height: 1px;
  background: rgba(255, 255, 255, 0.38);
}
.ground-floor .fbox1 .fbox2 .lianxi {
  display: flex;
  flex-direction: column;
  width: 293px;
  height: 394px;
}
.ground-floor .fbox1 .fbox2 .lianxi .fico {
  display: inline-block;
  width: 134px;
  height: 40px;
  font-size: 20px;
}
.ground-floor .fbox1 .fbox2 .lianxi .fico .ficon {
  display: inline-block;
  width: 46px;
  height: 40px;
}
.ground-floor .fbox1 .fbox2 .lianxi .fico .fdesignation {
  float: right;
  line-height: 40px;
  color: #FFFFFF;
}
.ground-floor .fbox1 .fbox2 .lianxi .contway {
  display: flex;
  flex-direction: column;
  width: 263px;
  height: 80px;
  margin-top: 31px;
}
.ground-floor .fbox1 .fbox2 .lianxi .contway .special {
  color: #FFFFFF;
  margin-top: 12px;
  font-size: 14px;
}
.ground-floor .fbox1 .fbox2 .lianxi .QR-code {
  display: inline-block;
  width: 293px;
  height: 122px;
  margin-top: 21px;
  /*background-color: #262C50;*/
}
.ground-floor .fbox1 .fbox2 .lianxi .QR-code .QR-code1 {
  width: 122px;
  height: 122px;
  display: block;
  margin: 0 auto;
  background-color: #979797;
}
.ground-floor .fbox1 .fbox2 .cut-line {
  width: 1px;
  height: 280px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.38);
}
.ground-floor .fbox1 .fbox2 .explain {
  width: 832px;
  height: 394px;
  /*background-color: black;*/
}
.ground-floor .fbox1 .fbox2 .explain .explain1 {
  display: flex;
  width: 770px;
  height: 394px;
  margin-left: 63px;
  /*background-color: red;*/
}
.ground-floor .fbox1 .fbox2 .explain .explain1 .rice {
  display: flex;
}
.ground-floor .fbox1 .fbox2 .explain .explain1 .rice1 span {
  color: #FFFFFF;
  font-size: 18px;
  margin-bottom: 45px;
}
.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 {
  display: flex;
  flex-direction: column;
  width: 186px;
  height: 394px;
}
.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 ul li {
  margin-bottom: 26px;
}
.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 ul li span {
  font-size: 14px;
}
.ground-floor .fbox1 .safety{
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.ground-floor .fbox1 .safety span{
  line-height: 70px;
  color: #FFFFFF;
  font-size: 14px;
}
</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}

.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}

.el-carousel__arrow--left {
  border: 1px solid #124BFF;
}

.el-carousel__arrow--right {
  border: 1px solid #124BFF;
}

.el-carousel__arrow i {
  color: white;
}
</style>